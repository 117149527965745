import { UserProfileService } from './core/services/firestore-data/user-profile/user-profile.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthService } from './core/services/firestore-auth/auth/auth.service';
import { ThemeService } from './core/services/theme/theme.service';
import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import ls from '@livesession/sdk';
import { SplashScreen } from '@capacitor/splash-screen';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { WebsiteIdentificationService } from './custom/services/firestore-data/website-identification/website-identification.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})




export class AppComponent implements OnInit, AfterViewInit {

  hmac: string;
  host: string;
  session: string;
  shop: string;
  timestamp: string;


  constructor(
    private themeService: ThemeService,
    private authService: AuthService,
    private angularFireAuth: AngularFireAuth,
    private userProfileService: UserProfileService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private websiteIdentificationService: WebsiteIdentificationService
  ) {
    this.angularFireAuth.authState.subscribe((user) => {
      if (user) {
        this.userProfileService.getCurrentUser();
        this.authService.getUserClaims();
      } else {
        // this.angularFireAuth.signInAnonymously();
      }
    });
  }

  async ngAfterViewInit(): Promise<void> {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    // this.checkTheme();
    this.getActivatedRoute();
    // this.websiteIdentificationService.checkBaseUrl();
    SplashScreen.hide();

  }

  async ngOnInit(): Promise<void> {
    await SplashScreen.show({
      showDuration: 3000,
      autoHide: true
    });
    // ls.init('046fa07d.99934581');
    // ls.newPageView();
    // await StatusBar.setStyle({ style: Style.Light });
    //  read shopify url params

  }

  checkTheme() {
    this.themeService.checkPreferedColorScheme();
  }

  getActivatedRoute() {
    const route = this.router.url;
    // console.log('route', route);
    this.activatedRoute.params.subscribe((params) => {
      // console.log('params', params);
    }
    );
    const location = this.location.path();
    const url = this.router.url;
    const path = this.location.path();
    const windowLocation = window.location;
    const windowOrigin = window.location.origin;

    // const origin = location.origin;
    console.log('url', url);
    console.log('location', location);
    console.log('path', path);
    console.log('windowLocation', windowLocation);
    console.log('windowOrigin', windowOrigin);

  }
}
