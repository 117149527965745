import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { WebsiteIdentificationService } from '../services/firestore-data/website-identification/website-identification.service';
import '@shopify/shopify-api/adapters/web-api';
import {shopifyApi, LATEST_API_VERSION, RequestedTokenType} from '@shopify/shopify-api';
import { TokenExchangeParams } from '@shopify/shopify-api/dist/ts/lib/auth/oauth/token-exchange';
import { ShopifyAuthRequestService } from '../services/firestore-data/shopify-auth-request/shopify-auth-request.service';
import { ShopifyAuthRequest } from '../model/shopify-auth-request.model';
import createApp from '@shopify/app-bridge';
import {Toast, AuthCode, } from '@shopify/app-bridge/actions';
import { getSessionToken } from "@shopify/app-bridge/utilities";
import { AngularFireAuth } from '@angular/fire/compat/auth';





@Injectable({
  providedIn: 'root'
})
export class WebsiteIdentificationGuard {


  constructor(
    private websiteIdentificationService: WebsiteIdentificationService,
    private shopifyAuthRequestService: ShopifyAuthRequestService,
    private afAuth: AngularFireAuth,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise(async (resolve, reject) => {
      console.log('checking website identification');
      console.log('route', route);
      const params = route.params.webId;
      console.log('params', params);
      console.log('state', state);
      await this.websiteIdentificationService.checkBaseUrl(route); 
      // this.checkShopifyUrl(route);


      resolve(true);
    });
  }


  // checkShopifyUrl(route: ActivatedRouteSnapshot): boolean {
  //   console.log('checking shopify url');
  //   console.log('route', route);
  //   const params = route.queryParams;
  //   const hmac = params.hmac;
  //   const host = params.host;
  //   const session = params.session;
  //   const shop = params.shop;
  //   const timestamp = params.timestamp;
  //   console.log('hmac', hmac);
  //   console.log('host', host);
  //   console.log('session', session);
  //   console.log('shop', shop);
  //   console.log('timestamp', timestamp);
  //   // this.startShopifyTokenExchange(shop, session, host, timestamp, hmac);
  //   this.setupAppBridge(shop, session, host, timestamp, hmac);

  //   return true;
  // }

  // async startShopifyTokenExchange(shop: string, sessionToken:string, host:string, timestamp:number, hmac:string): Promise<void> {
  //   const userId = (await this.afAuth.currentUser).uid;
  //   const newRequest: ShopifyAuthRequest = {
  //     id: host,
  //     shop: shop,
  //     sessionToken: sessionToken,
  //     host: host,
  //     isProcessed: false,
  //     accessToken: '',
  //     hmac: hmac,
  //     timestamp: timestamp,
  //     ownerId: userId,
  //   }
  //   this.shopifyAuthRequestService.set(newRequest);
  //   return;
  // }

  // async setupAppBridge(shop: string, session:string, host:string, timestamp:number, hmac:string): Promise<void> {
  //   const app = createApp({
  //     apiKey: '442d9b9db0e5380c190058264c47ef7b',
  //     host: host,
  //   });
  //   console.log('app', app);
  //   Toast.create(app, {
  //     message: 'Hello world!',
  //     duration: 5000,
  //   }).dispatch(Toast.Action.SHOW);
  //   const sessionToken = await getSessionToken(app);
  //   console.log('sessionToken', sessionToken);
  //   this.startShopifyTokenExchange(shop, sessionToken, host, timestamp, hmac);
  // }
}
