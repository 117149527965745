import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ShopifyAuthRequestService } from '../services/firestore-data/shopify-auth-request/shopify-auth-request.service';
import { WebsiteIdentificationService } from '../services/firestore-data/website-identification/website-identification.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectIdentificationGuard {


  constructor(
    private websiteIdentificationService: WebsiteIdentificationService,
    private shopifyAuthRequestService: ShopifyAuthRequestService,
    private afAuth: AngularFireAuth,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise(async (resolve, reject) => {
      console.log('checking website identification');
      console.log('route', route);
      const params = route.params.webId;
      console.log('params', params);
      console.log('state', state);
      await this.websiteIdentificationService.checkWebIdUrl(route); 
      // this.checkShopifyUrl(route);


      resolve(true);
    });
  }


 
}
