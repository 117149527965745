import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor() {}

  checkPreferedColorScheme() {
    const systemDark = window.matchMedia('(prefers-color-scheme: dark)');
    // if(systemDark.matches){
    this.changeMode(systemDark.matches);
    // }

    systemDark.addListener((e) => e.matches && this.changeMode(e.matches));
  }

  changeMode(changeToDark: boolean) {
    if (changeToDark) {
      document.body.setAttribute('color-theme', 'dark');
    } else {
      document.body.setAttribute('color-theme', 'light');
    }

    // set style to dark
  }
}
