import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FirestoreDataService } from 'src/app/core/services/firestore-data/firestore-data.service';
import { ShopifyAuthRequest } from 'src/app/custom/model/shopify-auth-request.model';

@Injectable({
  providedIn: 'root'
})
export class ShopifyAuthRequestService extends FirestoreDataService<ShopifyAuthRequest> {
  constructor(afs: AngularFirestore) {
    super("ShopifyAuthRequests", afs);
  }
}
