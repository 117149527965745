import { loadTranslations } from "@angular/localize";
import { environment } from "src/environments/environment";

export const initializeLanguage = (): Promise<void> | void => {
  const availableLocales = environment.langs.map(l => l.key);
  var locale = defineUserLanguage();
  console.log('loaded language: ' + locale);
  window.document.documentElement.lang = locale;


  if (locale !== availableLocales[0]) {
    // fetch resources for runtime translations. this could also point to an API endpoint
    fetch(`assets/i18n/messages.${locale}.json`)
      .then((response) => response.json())
      .then((result) => {
        loadTranslations(result.translations);
      })
      .catch(() => console.log(`language ${locale} not found, fallback to default`));
  }

}

export const defineUserLanguage = () => {
  const availableLocales = environment.langs.map(l => l.key);
  const cookieLang = document.cookie
    .split('; ')
    .find(c => c.startsWith('lang='))
    ?.split('=')[1];

  // check order is: cookie -> navigator (browser) -> default (first defined in environment)
  let locale = cookieLang ??
    (availableLocales.find((l) => navigator.language.startsWith(l)) ??
      availableLocales[0]);
  return locale;
}
