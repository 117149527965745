import { initializeLanguage } from './core/app.initializers';
import { SwiperModule } from 'swiper/angular';
import { NgModule, LOCALE_ID, APP_INITIALIZER, isDevMode } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/compat/functions';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  AngularFireAnalyticsModule,
  DEBUG_MODE,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/compat/analytics';
import { registerLocaleData } from '@angular/common';
import deCh from '@angular/common/locales/de-CH';
import {AngularFireAuthModule } from '@angular/fire/compat/auth';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CookieConsentModule } from './custom/components/cookie-consent/cookie-consent.module';

registerLocaleData(deCh);

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function playerFactory() {
  return player;
}


@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        IonicModule.forRoot({
            mode: 'ios',
        }),
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        // enable offline data persistence
        AngularFireAnalyticsModule,
        AngularFireFunctionsModule,
        // AngularFirestoreModule.enablePersistence(),
        LottieModule.forRoot({ player: playerFactory }),
        SwiperModule,
        AngularFirestoreModule,
        BrowserAnimationsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: LOCALE_ID, useValue: 'de-CH' },
        { provide: DEBUG_MODE, useValue: !environment.production },
        { provide: REGION, useValue: 'europe-west6' },
        { provide: APP_INITIALIZER, useFactory: () => initializeLanguage, multi: true },
        ScreenTrackingService,
        UserTrackingService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
