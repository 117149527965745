// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAc1tN77w73u2wD4-_bG4Yn5Oc-Gy9VHTs",
    authDomain: "anothercms-dev.firebaseapp.com",
    projectId: "anothercms-dev",
    storageBucket: "anothercms-dev.appspot.com",
    messagingSenderId: "698723171214",
    appId: "1:698723171214:web:d2fd5d25b90c4ba63902df",
    measurementId: "G-NGFJMGY8H5"
  },
  langs: [
    { key: 'en', name: 'English' },
    { key: 'de', name: 'Deutsch' },
    { key: 'fr', name: 'Français' },
  ],
  shopifyAppBridgeApiKey: 'ff60a0aa43b4e032ae8da3a272efa881'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
