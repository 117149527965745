import { CompleteUserProfileGuard } from './core/guards/complete-user-profile/complete-user-profile.guard';
// import { MissingUserInfoGuardGuard } from './core/guards/missing-user-info/missing-user-info-guard.guard';
// import { SetupHerbyWifiModule } from './core/components/client/devices/herby/setup-herby-wifi/setup-herby-wifi.module';
import { AdminPageGuard } from './core/guards/admin-page.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { WebsiteIdentificationGuard } from './custom/guards/website-identification.guard';
import { ShopifySessionGuard } from './custom/guards/shopify-session.guard';
import { ProjectIdentificationGuard } from './custom/guards/project-identification.guard';

const routes: Routes = [
  {
    path: '',
    // canActivate: [WebsiteIdentificationGuard],
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      // {
      //   path: 'home',
      //   loadChildren: () => import('./core/pages/home/home.module').then( m => m.HomePageModule)
      // },
      {
        path: 'admin',
        loadChildren: () =>
          import('./core/pages/admin/admin.module').then((m) => m.AdminPageModule),
        canActivate: [AdminPageGuard],
      },
      {
        path: 'auth',
        loadChildren: () =>
          import('./core/pages/auth/auth.module').then((m) => m.AuthPageModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'app',
        loadChildren: () =>
          import('./core/pages/app/tabs/tabs.module').then((m) => m.TabsPageModule),
        canActivate: [CompleteUserProfileGuard],
      },
      // {
      //   path: 'tabs/:id',
      //   loadChildren: () => import('./core/pages/app/tabs/tabs.module').then((m) => m.TabsPageModule),
      //   canActivate: [],
      // },
      {
        path: 'prev/:id',
        loadChildren: () =>
          import('./core/pages/main/main.module').then((m) => m.MainPageModule),
        canActivate: [AuthGuard],
        // canActivate: [AngularFireAuthGuard]
      },
      // {
      //   path: 'app/dashboard',
      //   loadChildren: () =>
      //     import('./core/pages/app/dashboard/dashboard.module').then(
      //       (m) => m.DashboardPageModule
      //     ),
      //   canActivate: [AuthGuard, Com],
      // },
      {
        path: 'basket',
        loadChildren: () =>
          import('./core/pages/client/shop/basket/basket.module').then(
            (m) => m.BasketPageModule
          ),
      },
      {
        path: 'checkout',
        loadChildren: () =>
          import('./core/pages/client/shop/checkout/checkout.module').then(
            (m) => m.CheckoutPageModule
          ),
      },
      {
        path: 'on-boarding',
        loadChildren: () =>
          import('./core/pages/client/on-boarding/on-boarding.module').then(
            (m) => m.OnBoardingPageModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: ':webId/editor',
        loadChildren: () => import('./custom/pages/app/editor/editor.module').then(m => m.EditorPageModule),
        canActivate: [WebsiteIdentificationGuard, AuthGuard],
      },
      {
        path: 'editor/:webId',
        loadChildren: () => import('./custom/pages/app/editor/editor.module').then(m => m.EditorPageModule),
        canActivate: [ProjectIdentificationGuard, AuthGuard],
      },
      {
        path: ':id',
        loadChildren: () =>
          import('./core/pages/published/published.module').then(
            (m) => m.PublishedPageModule
          ),
        canActivate: [AuthGuard, WebsiteIdentificationGuard],
      },
      {
        path: 'knowledge/:id',
        loadChildren: () =>
          import('./core/pages/published/published.module').then(
            (m) => m.PublishedPageModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'knowledge/plants/:id',
        loadChildren: () =>
          import('./core/pages/published/published.module').then(
            (m) => m.PublishedPageModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'checkout-success/:id',
        loadChildren: () =>
          import(
            './core/pages/client/shop/checkout/checkout-success/checkout-success.module'
          ).then((m) => m.CheckoutSuccessPageModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'app/website',
        children: [
          {
            path: 'create',
            loadChildren: () =>
              import(
                './custom/pages/app/website-create/website-create.module'
              ).then((m) => m.WebsiteCreatePageModule),

            canActivate: [AuthGuard],
          },
          {
            path: ':id/add-logo',
            loadChildren: () =>
              import(
                './custom/pages/app/website-add-logo/website-add-logo.module'
              ).then((m) => m.WebsiteAddLogoPageModule),
            canActivate: [AuthGuard],
          },
          {
            path: ':webId/choose-font',
            loadChildren: () => import('./custom/pages/app/website-choose-font/website-choose-font.module').then(m => m.WebsiteChooseFontPageModule)
          },
          {
            path: ':webId/choose-colors',
            loadChildren: () => import('./custom/pages/app/website-choose-colors/website-choose-colors.module').then(m => m.WebsiteChooseColorsPageModule)
          },
          {
            path: ':webId/choose-template',
            loadChildren: () => import('./custom/pages/app/website-choose-template/website-choose-template.module').then( m => m.WebsiteChooseTemplatePageModule)
          },
          {
            path: ':webId/page-editor',
            children: [{
              path: '',
              loadChildren: () => import('./custom/pages/app/page-editor/page-editor.module').then(m => m.PageEditorPageModule),
              canActivate: [AuthGuard],
            },

            {
              path: ':id',
              loadChildren: () => import('./custom/pages/app/page-editor/page-editor.module').then(m => m.PageEditorPageModule),
              canActivate: [AuthGuard],
            }
            ]
          },
          {
            path: ':webId/page-editor-mobile',
            children: [{
              path: '',
              loadChildren: () => import('./custom/pages/app/page-editor-mobile/page-editor-mobile.module').then(m => m.PageEditorMobilePageModule)
            },
            {
              path: ':id',
              loadChildren: () => import('./custom/pages/app/page-editor-mobile/page-editor-mobile.module').then(m => m.PageEditorMobilePageModule)
            }
            ]
          },
          {
            path: ':webId/category-editor',
            children: [
              {
                path: ':id',
                loadChildren: () =>
                  import(
                    './custom/pages/app/category-editor/category-editor.module'
                  ).then((m) => m.CategoryEditorPageModule),
                canActivate: [AuthGuard],
              },
            ],
          },
          {
            path: ':webId/entry-editor/:categoryId',
            children: [
              {
                path: '',
                loadChildren: () =>
                  import(
                    './custom/pages/app/entry-editor/entry-editor.module'
                  ).then((m) => m.EntryEditorPageModule),
                canActivate: [AuthGuard],
              },
              {
                path: ':id',
                loadChildren: () =>
                  import(
                    './custom/pages/app/entry-editor/entry-editor.module'
                  ).then((m) => m.EntryEditorPageModule),
                canActivate: [AuthGuard],
              },
            ],
          },
        ],

      },
      {
        path: ':webId/:id',
        children:
          [{
            path: '',
            loadChildren: () =>
              import('./core/pages/published/published.module').then(
                (m) => m.PublishedPageModule
              ),
          },
          {
            path: ':entryId',
            loadChildren: () =>
              import('./core/pages/main/main.module').then(
                (m) => m.MainPageModule
              ),
          },
          ],
        canActivate: [AuthGuard, WebsiteIdentificationGuard],
      },
    ],
  },
 





  // {
  //   path: ':parent/:id',
  //   loadChildren: () =>
  //     import('./pages/main/main.module').then((m) => m.MainPageModule),
  //   canActivate: [],
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
