import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FirestoreDataService } from 'src/app/core/services/firestore-data/firestore-data.service';
import { WebsiteRegistry } from 'src/app/custom/model/website-registry.model';

@Injectable({
  providedIn: 'root'
})
export class WebsiteRegistryService extends FirestoreDataService<WebsiteRegistry> {
  constructor(afs: AngularFirestore) {
    super("WebsiteRegistry", afs);
  }
}
