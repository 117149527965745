import { Injectable } from '@angular/core';
import { WebsiteRegistryService } from '../website-registry/website-registry.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { UrlRegistry } from 'src/app/custom/model/url-registry.model';
import { UrlRegistryService } from '../url-registry/url-registry.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebsiteIdentificationService {
  public currentWebId: string;
  public editorProjectId: string;


  constructor(
    private webSiteRegistryService: WebsiteRegistryService,
    private urlRegistryService: UrlRegistryService
  ) { }


  async checkBaseUrl(activatedRoute: ActivatedRouteSnapshot) {
    let windowOrigin = window.location.origin
    let webId: string = "home"
    console.log('windowOrigin', windowOrigin);
    // strip http:// or https://
    windowOrigin = windowOrigin.replace(/(^\w+:|^)\/\//, '');
    console.log('windowOrigin', windowOrigin);
    const fullDomain: string = windowOrigin;
    // const subdomain: string = windowOrigin.split('.')[0];
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.').slice(0, -2).join('.');
    console.log('subdomain', subdomain);
    console.log('hostname', hostname);
    // let webId: string = "home";
    if (fullDomain.includes('localhost' || 'anothercms.org')) {
      if (subdomain === '') {
        console.log('subdomain is empty');
        // get default project
      } else {
        // get project by subdomain
        console.log('subdomain is not empty');
        webId = subdomain;
      }

    } else {
      // checkForTopLevelDomain
    }

    // if (windowOrigin.includes('localhost') || windowOrigin.includes('anothercms.org')) {
    //   const routeWebId = activatedRoute.params.webId;
    //   console.log('routeWebId', routeWebId);
    //   if (routeWebId) {
    //     webId = routeWebId;
    //   } else {
    //     // checkForSubdomain
    //     // let subdomain = windowOrigin.split('.')[0];
    //     const urlReg = await firstValueFrom(this.urlRegistryService.get(windowOrigin))
    //     if (urlReg) {
    //       console.log('urlReg', urlReg);
    //       if (urlReg.isValidated) {
    //         webId = urlReg.projectId;
    //       }
    //     } else {
    //       //  checkforwebregistry
    //       // const routeId: string = activatedRoute.params.id;
    //       // const webReg = await firstValueFrom(this.webSiteRegistryService.get(routeId));
    //       // if (webReg) {
    //       //   console.log('webReg', webReg);
    //       //   webId = webReg.id;
    //       // }
    //     }

    //   }
    // } else {
    //   // checkForTopLevelDomain
    // }
    this.currentWebId = webId;
    console.log('currentWebId', this.currentWebId);

  }

  async checkWebIdUrl(activatedRoute: ActivatedRouteSnapshot) {
    let windowOrigin = window.location.origin
    let webId: string = "home"
    console.log('windowOrigin', windowOrigin);
    // strip http:// or https://
    windowOrigin = windowOrigin.replace(/(^\w+:|^)\/\//, '');
    if (windowOrigin.includes('localhost') || windowOrigin.includes('anothercms.org')) {
      const routeWebId = activatedRoute.params.webId;
      console.log('routeWebId', routeWebId);
      if (routeWebId) {
        webId = routeWebId;
      } else {
        console.log('no routeWebId');
      }
    } else {
      // checkForTopLevelDomain
    }
    this.currentWebId = webId;
  }

}
